import React from "react";
import { ArticleHero } from "@molecules";
import ContentBuilder from "./ContentBuilder";

const ArticleBody = ({ hero, blocks, url }) => {
  return (
    <section className="py-12 md:py-28">
      <ArticleHero {...hero} />
      <ContentBuilder blocks={blocks} />
    </section>
  );
};

ArticleBody.defaultProps = {};

export default ArticleBody;
